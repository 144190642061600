import classnames from 'classnames'
import NextImage from 'components/NextImage/NextImage'
import SpaLink from 'components/SpaLink/SpaLink'
import { Image } from 'frontend/types'
import * as Edith from 'backend/services/v1/edithService.types'
import ExternalLinkIcon from 'assets/icons/external-link.svg'
import styles from './SingleBlock.module.css'

type SingleBlockProps = {
  alignDirection: string
  description: string | null
  image: Image | string
  link: string
  linkTitle: string
  title: string | null
  onClick: () => void
  blockIndex: number
  type: string
}

type SingleBlockContentProps = Omit<SingleBlockProps, 'onClick'> & {
  onClick?: () => void
}

const SingleBlockContent = ({
  alignDirection,
  description,
  image,
  link,
  onClick,
  linkTitle,
  title,
  blockIndex,
  type,
}: SingleBlockContentProps) => {
  const linkIsExternal = !link.includes('https://urplay')

  return (
    <div
      data-testid="single-block-wrapper"
      className={classnames(styles.singleBlockWrapper, {
        [`${styles.alignTextLeft}`]: alignDirection === 'left',
      })}
    >
      <figure className={styles.figure}>
        <NextImage
          className={styles.image}
          width={1280}
          height={image ? 720 : 0}
          sizes="(min-width: 1600px) 1216px, (min-width: 1201px) calc(100vw - 16vw), 100vw"
          alt=""
          src={typeof image === 'string' ? image : image['1280x720'] || ''}
          {...(blockIndex < 3 && { priority: true })}
        />
      </figure>

      <div className={styles.text}>
        {type !== Edith.EdithProductType.LINK_PRODUCT && (
          <div className={styles.linkTitle}>{linkTitle}</div>
        )}
        <h2 className={styles.title}>{title}</h2>
        {description && <p className={styles.description}>{description}</p>}
        {type === Edith.EdithProductType.LINK_PRODUCT && (
          <SpaLink
            href={link}
            className={classnames(styles.linkButton)}
            onClick={onClick}
            {...(linkIsExternal && {
              target: '_blank',
              rel: 'noopener',
            })}
          >
            {linkTitle}
            {linkIsExternal && (
              <ExternalLinkIcon
                className={styles.externalLinkIcon}
                data-testid="externalLinkIcon"
              />
            )}
          </SpaLink>
        )}
      </div>
    </div>
  )
}

const SingleBlock = ({
  alignDirection,
  description,
  image,
  link,
  linkTitle,
  title,
  onClick,
  blockIndex,
  type,
}: SingleBlockProps) => {
  return (
    <div className={styles.section} data-testid="SingleBlock">
      {type !== Edith.EdithProductType.LINK_PRODUCT ? (
        <SpaLink
          href={link}
          aria-label={`Se mer om ${title}`}
          className={styles.link}
          onClick={onClick}
        >
          <SingleBlockContent
            alignDirection={alignDirection}
            description={description}
            image={image}
            link={link}
            linkTitle={linkTitle}
            title={title}
            blockIndex={blockIndex}
            type={type}
          />
        </SpaLink>
      ) : (
        <SingleBlockContent
          alignDirection={alignDirection}
          description={description}
          image={image}
          link={link}
          linkTitle={linkTitle}
          title={title}
          onClick={onClick}
          blockIndex={blockIndex}
          type={type}
        />
      )}
    </div>
  )
}
export default SingleBlock
