import classnames from 'classnames'
import Header from 'components/ProductList/Header/Header'
import {
  BlockTracking,
  EdithBlockProgram,
  EdithBlockSeries,
  EdithBlockTrailer,
  LinkProduct,
} from 'frontend/types'
import { blockItemClick } from 'frontend/utils/analytics/dataLayerDispatchers'
import styles from './FeaturedMediaGridContainer.module.css'
import FeaturedMediaGridItem from './components/FeaturedMediaGridItem/FeaturedMediaGridItem'

type FeaturedMediaGridContainerProps = {
  productList: (
    | EdithBlockProgram
    | EdithBlockSeries
    | EdithBlockTrailer
    | LinkProduct
  )[]
  blockTracking: BlockTracking
  title: string | null
  showTitle: boolean
}

const FeaturedMediaGridContainer = ({
  productList,
  blockTracking,
  title,
  showTitle = false,
}: FeaturedMediaGridContainerProps) => {
  const listLength = productList.length

  return (
    <div className={styles.container}>
      {title && showTitle && <Header title={title} />}
      <div
        className={classnames(styles.fmgGrid, {
          [`${styles.one}`]: listLength === 1,
          [`${styles.two}`]: listLength === 2,
          [`${styles.three}`]: listLength === 3,
          [`${styles.four}`]: listLength === 4,
          [`${styles.five}`]: listLength === 5,
        })}
        data-testid="FMGContainer"
      >
        {productList.map((product, index) => (
          <FeaturedMediaGridItem
            item={product}
            blockIndex={blockTracking.blockIndex}
            key={'id' in product && product.id ? product.id : product.link}
            onClick={() =>
              blockItemClick({ blockTracking, itemIndex: index + 1, product })
            }
          />
        ))}
      </div>
    </div>
  )
}

export default FeaturedMediaGridContainer
