import NextImage from 'components/NextImage/NextImage'
import SpaLink from 'components/SpaLink/SpaLink'
import { BlockTracking } from 'frontend/types'
import { blockItemClick } from 'frontend/utils/analytics/dataLayerDispatchers'
import React from 'react'
import styles from './CategoryBlock.module.css'
import { CategoryForBlock } from 'backend/services/v1/edithService.types'

interface CategoryBlockProps {
  itemList: CategoryForBlock[]
  parentSlug?: string
  title: string | null
  children?: React.ReactNode
  className?: string
  blockTracking: BlockTracking
}

export const CategoryBlockItem = ({
  children,
  slug,
  parentSlug,
  label,
  blockTracking,
  itemIndex,
  imageUrl,
}: {
  children: React.ReactNode
  slug: string
  parentSlug: string
  label: string
  blockTracking: BlockTracking
  itemIndex: number
  imageUrl: string
}) => {
  const href = parentSlug
    ? `/bladdra/${parentSlug}/${slug}`
    : `/bladdra/${slug}`

  return (
    <div className={styles.categoryBlockItem}>
      <NextImage
        className={styles.categoryImage}
        src={imageUrl}
        alt={'En bild'}
        width={640}
        height={360}
        fallbackSrc="/images/categories/fallback.jpg"
        sizes="(min-width: 1600px) 8vw, (min-width: 1201px) 10vw, (min-width: 1024px) 15vw, (min-width: 950px) 2vw, (min-width: 760px) 15vw, (min-width: 560px) 20vw, 100px"
      />
      <SpaLink
        onClick={() => {
          blockItemClick({
            blockTracking,
            itemIndex,
            item: { link: href, title: label },
          })
        }}
        href={href}
        className={styles.categoryLink}
        data-trk-category-card={label}
      >
        <div className={styles.categoryName}>{children}</div>
      </SpaLink>
    </div>
  )
}

const CategoryBlock = ({
  itemList,
  parentSlug = '',
  title,
  children,
  className,
  blockTracking,
}: CategoryBlockProps) => {
  return (
    <div className={className}>
      {title && (
        <header className={styles.header}>
          <h2 data-testid="category-block-header">{title}</h2>
        </header>
      )}
      <div className={styles.wrapper} data-testid="CategoryBlock">
        {children}
        {itemList.map((item, index) => {
          return (
            <CategoryBlockItem
              parentSlug={parentSlug}
              slug={item.slug}
              label={item.name}
              key={item.slug}
              blockTracking={blockTracking}
              itemIndex={index + 1}
              imageUrl={item.imageUrl}
            >
              {item.name}
            </CategoryBlockItem>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryBlock
