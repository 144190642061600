import React from 'react'
import styles from './TextBlock.module.css'

interface TextBlockProps {
  description: string | null
  title: string | null
}

const TextBlock = ({ description, title }: TextBlockProps) => {
  return (
    <div data-testid="TextBlock">
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
  )
}

export default TextBlock
