import {
  EdithBlockLayout,
  EdithBlockType,
} from 'backend/services/v1/edithService.enums'
import ContinuePlaying from 'components/ContinuePlaying/ContinuePlaying'
import FeaturedMediaGridContainer from 'components/FeaturedMediaGrid/FeaturedMediaGridContainer'
import LinkBlock from 'components/LinkBlock/LinkBlock'
import ProductListButtonContainer from 'components/ProductList/Buttons/ProductListButtonContainer'
import ProductListToggleButton from 'components/ProductList/Buttons/ProductListToggleButton'
import Header from 'components/ProductList/Header/Header'
import ProductList from 'components/ProductList/ProductList'
import ProductListCards from 'components/ProductList/ProductListCards/ProductListCards'

import CategoryBlock from 'components/CategoryBlock/CategoryBlock'
import Recommendations from 'components/Recommendations/Recommendations'
import SingleBlock from 'components/SingleBlock/SingleBlock'
import TextBlock from 'components/TextBlock/TextBlock'
import { ProductListLayout } from 'frontend/enums'
import { Block, LinkProduct } from 'frontend/types'
import { blockItemClick } from 'frontend/utils/analytics/dataLayerDispatchers'
import { useState } from 'react'
import { EdithCardLayout } from 'backend/services/v1/edithService.types'

interface EdithBlockProps {
  block: Block
  blockIndex: number
  isChildPage?: boolean
}

const EdithBlock = ({ block, blockIndex, isChildPage }: EdithBlockProps) => {
  const [layout, setLayout] = useState(ProductListLayout.SHOW_LESS)

  switch (block.blockType) {
    case EdithBlockType.CATEGORIES:
      return (
        <CategoryBlock
          title="Kategorier"
          itemList={block.categories || []}
          blockTracking={{
            blockIndex,
            blockType: 'categories',
            blockTitle: block.title,
          }}
        />
      )
    case EdithBlockType.CONTINUE_PLAYING:
      return (
        <ContinuePlaying
          isChildPage={isChildPage}
          blockTracking={{
            blockIndex,
            blockType: 'continue_playing',
            blockTitle: block.title,
          }}
        />
      )
    case EdithBlockType.RECOMMENDATIONS:
      return (
        <Recommendations
          title={block.title}
          endpoint={block?.peach_parameters?.endpoint || ''}
          blockTracking={{
            blockIndex,
            blockType: 'recommendations',
            blockTitle: block.title,
          }}
        />
      )
    default:
      break
  }

  switch (block.layout) {
    case EdithBlockLayout.FMG:
      return (
        <FeaturedMediaGridContainer
          productList={block.products || []}
          title={block.title}
          showTitle={block.showTitle || false}
          blockTracking={{
            blockIndex,
            blockType: 'featured_mediagrid',
            blockTitle: block.title || '',
          }}
        />
      )
    case EdithBlockLayout.LIST:
      return (
        block.products &&
        block.products.length > 0 && (
          <ProductList>
            <Header title={block.title}>
              <ProductListButtonContainer>
                <ProductListToggleButton
                  numProducts={block.products.length}
                  layout={layout}
                  setLayout={setLayout}
                />
              </ProductListButtonContainer>
            </Header>
            <ProductListCards
              headingLevel={3}
              showAllCards={ProductListLayout.SHOW_MORE === layout}
              products={
                ProductListLayout.SHOW_LESS === layout
                  ? block.products.slice(0, 5)
                  : block.products
              }
              cardLayout={block.cardLayout || EdithCardLayout.DEFAULT}
              blockTracking={{
                blockIndex,
                blockType: 'list',
                blockTitle: block.title,
              }}
            />
          </ProductList>
        )
      )
    case EdithBlockLayout.SINGLE:
      if (block.products && block.products.length === 0) {
        return null
      } else if (block.products && block.products.length > 0) {
        return (
          <SingleBlock
            alignDirection={block.align || 'left'}
            description={block.description}
            image={
              'imageUrl' in block.products[0]
                ? block.products[0].imageUrl
                : block.products[0].image
            }
            link={(block.products[0] as LinkProduct).link}
            linkTitle={block.products[0].title}
            title={block.title}
            type={'type' in block.products[0] ? block.products[0].type : ''}
            onClick={() => {
              blockItemClick({
                blockTracking: {
                  blockIndex,
                  blockType: 'single',
                  blockTitle: block.title,
                },
                product: block.products?.[0],
                itemIndex: 1,
              })
            }}
            blockIndex={blockIndex}
          />
        )
      }
    case EdithBlockLayout.TEXT:
      return <TextBlock description={block.description} title={block.title} />
    case EdithBlockLayout.LINK:
      return (
        <LinkBlock
          links={block.products as LinkProduct[]}
          blockTracking={{
            blockIndex,
            blockType: 'link',
            blockTitle: block.title,
          }}
        />
      )

    default:
      return null
  }
}

export default EdithBlock
