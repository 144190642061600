import styles from './HiddenHeading.module.css'

interface Props {
  text: string | React.ReactNode
  level?: 'h1' | 'h2'
}

const HiddenHeading = ({ text, level = 'h1' }: Props) => {
  if (level === 'h1') {
    return <h1 className={styles.heading}>{text}</h1>
  }
  return <h2 className={styles.heading}>{text}</h2>
}

export default HiddenHeading
