import { AllowSoftHyphen } from 'components/AllowSoftHyphen/AllowSoftHyphen'
import NextImage from 'components/NextImage/NextImage'
import SpaLink from 'components/SpaLink/SpaLink'
import { MediaFormat } from 'frontend/enums'
import { EdithBlockProduct } from 'frontend/types'
import { isExternalLinkProduct, truncate } from 'frontend/utils/helpers'
import React from 'react'
import Badges from '../../../Badges/Badges'
import ProgressBarContainer from '../../../ProgressBar/ProgressBarContainer'
import styles from './FeaturedMediaGridItem.module.css'

const ActionLabel = ({ children }: { children: React.ReactNode }) => (
  <span className={styles.actionLabel}>{children}</span>
)

type FeaturedMediaGridItemProps = {
  onClick: () => void
  item: EdithBlockProduct
  blockIndex?: number
}

const FeaturedMediaGridItem = ({
  onClick,
  item,
  blockIndex,
}: FeaturedMediaGridItemProps) => {
  const usp = truncate(('usp' in item && item.usp) || item.description)

  const isRadioProduct = 'format' in item && item.format === MediaFormat.AUDIO

  return (
    <article data-exp="product-fmg" className={styles.fmgItem}>
      <SpaLink href={item.link} className={styles.itemLink} onClick={onClick}>
        <figure className={styles.figure}>
          <Badges
            isInFMGGrid
            isRadioProduct={isRadioProduct}
            isAudioDescribed={
              'isAudioDescribed' in item && item.isAudioDescribed
            }
            isSignLanguageInterpreted={
              'isSignLanguageInterpreted' in item &&
              item.isSignLanguageInterpreted
            }
            isExternalLinkProduct={isExternalLinkProduct(item)}
          />
          <div className={styles.imageWrap}>
            {'actionLabel' in item && item.actionLabel && (
              <ActionLabel>{item.actionLabel}</ActionLabel>
            )}
            <div className={styles.imageOverlay} />
            {'imageUrl' in item ? (
              <NextImage
                width={480}
                height={270}
                alt=""
                src={item.imageUrl}
                className={styles.image}
                {...(blockIndex && blockIndex < 3 && { priority: true })}
                sizes="(max-width: 639px) 70vw, (min-width: 1600px) calc(1216px * 0.5), (min-width: 1201px) calc((100vw - 16vw) * 0.5), (min-width: 768px) 98vw, (min-width: 640px) 48vw, 98vw"
              />
            ) : (
              <NextImage
                width={480}
                height={270}
                alt=""
                src={item.image['960x540']}
                className={styles.image}
                {...(blockIndex && blockIndex < 3 && { priority: true })}
                sizes="(max-width: 639px) 70vw, (min-width: 1600px) calc(1216px * 0.5), (min-width: 1201px) calc((100vw - 16vw) * 0.5), (min-width: 768px) 98vw, (min-width: 640px) 48vw, 98vw"
              />
            )}

            {'productType' in item && item.productType === 'program' && (
              <ProgressBarContainer id={item.id} />
            )}
          </div>
          <div data-exp="fmg-description" className={styles.textBox}>
            <h2 data-exp="fmg-title" className={styles.title}>
              <AllowSoftHyphen>{item.title}</AllowSoftHyphen>
            </h2>
            <p data-exp="fmg-usp" className={styles.usp}>
              {usp}
            </p>
          </div>
        </figure>
      </SpaLink>
    </article>
  )
}

export default FeaturedMediaGridItem
