import {
  EdithBlockLayout,
  EdithBlockType,
} from 'backend/services/v1/edithService.enums'
import classnames from 'classnames'
import { Block } from 'frontend/types'
import { ReactNode } from 'react'
import { InView } from 'react-intersection-observer'
import styles from './EdithContainer.module.css'
import EdithBlock from './components/EdithBlock'

interface EdithContainerProps {
  blocks: Block[]
  isChildPage?: boolean
}

export const generateBlockType = (block: Block) => {
  if (block.blockType === EdithBlockType.CATEGORIES) return 'categories'
  if (block.blockType === EdithBlockType.CONTINUE_PLAYING)
    return block.blockType
  if (block.layout === EdithBlockLayout.FMG) return 'featured_mediagrid'
  if (block.blockType === EdithBlockType.RECOMMENDATIONS) return block.blockType

  return block.layout
}

const BLOCKS_VISIBLE_ON_INITIAL_LOAD = 2

const LazyLoad = ({ children }: { children: ReactNode }) => {
  return (
    <InView rootMargin={'300px 0px'} triggerOnce>
      {({ inView, ref }) => (
        <section
          data-testid="lazyload-placeholder"
          className={classnames({
            [`${styles.lazyLoadPlaceholder}`]: !inView,
          })}
          ref={ref}
        >
          {inView && children}
        </section>
      )}
    </InView>
  )
}

const EdithContainer = ({
  blocks,
  isChildPage = false,
}: EdithContainerProps) => {
  return (
    <ul className={styles.wrapper} data-testid="edith-container">
      {blocks.map((block, index) => {
        const blockType = generateBlockType(block)

        return (
          <li
            className={styles.block}
            data-testid={`edith-container-block-${blockType}`}
            key={block.id}
          >
            {index < BLOCKS_VISIBLE_ON_INITIAL_LOAD ? (
              <section>
                <EdithBlock
                  block={block}
                  blockIndex={index + 1}
                  isChildPage={isChildPage}
                />
              </section>
            ) : (
              <LazyLoad>
                <EdithBlock
                  block={block}
                  blockIndex={index + 1}
                  isChildPage={isChildPage}
                />
              </LazyLoad>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default EdithContainer
