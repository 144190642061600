import React, { Fragment, ReactNode } from 'react'

interface AllowSoftHyphenProps {
  children: string
}

const SOFT_HYPHEN = '&shy;'

const insertBetweenEvery = (
  array: string[],
  builder: (index: number) => ReactNode,
): ReactNode[] => {
  return array.reduce((acc: ReactNode[], segment: string, index: number) => {
    return index === 0
      ? acc.concat(segment)
      : acc.concat(builder(index)).concat(segment)
  }, [])
}

export const AllowSoftHyphen = ({ children }: AllowSoftHyphenProps) => {
  const textSegments = children.split(SOFT_HYPHEN)
  return (
    <>
      {insertBetweenEvery(textSegments, i => (
        <Fragment key={i}>&shy;</Fragment>
      ))}
    </>
  )
}

export default AllowSoftHyphen
