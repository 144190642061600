import RightArrowIcon from 'assets/icons/arrow-right-white.svg'
import classnames from 'classnames'
import { BlockTracking } from 'frontend/types'
import SpaLink from 'components/SpaLink/SpaLink'
import { LinkProduct } from 'frontend/types'
import { blockItemClick } from 'frontend/utils/analytics/dataLayerDispatchers'
import { isExternalLinkProduct } from 'frontend/utils/helpers'
import React from 'react'
import styles from './LinkBlock.module.css'

interface LinkTextProps {
  id: number
  title: string
  description: string
  url: string
  targetBlank: boolean
  numberOfLinks: number
  blockTracking: BlockTracking
}
interface LinkBlockProps {
  links: LinkProduct[]
  blockTracking: BlockTracking
}

const LinkText = ({
  title,
  description,
  url,
  id,
  targetBlank,
  numberOfLinks,
  blockTracking,
}: LinkTextProps) => {
  return (
    <SpaLink
      href={url}
      onClick={() =>
        blockItemClick({
          blockTracking,
          itemIndex: id,
          item: { link: url, title },
        })
      }
      aria-label={`Se mer om ${title}`}
      className={classnames(styles.link, {
        [`${styles.oneLink}`]: numberOfLinks === 1,
        [`${styles.twoLinks}`]: numberOfLinks === 2,
      })}
      data-trk-link-card-index={id}
      data-trk-link-card={title}
      {...(targetBlank && { target: '_blank', rel: 'noopener' })}
    >
      <div className={styles.linkBlockContent}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
        </div>
        <div>
          <RightArrowIcon className={styles.linkIcon} />
        </div>
      </div>
    </SpaLink>
  )
}

const LinkBlock = ({ links, blockTracking }: LinkBlockProps) => {
  return (
    <div
      className={classnames(styles.linkBlockContainer, {
        [`${styles.linkBlockContainerOneLink}`]: links.length === 1,
      })}
    >
      {links.map((link, index) => {
        return (
          <LinkText
            id={index + 1}
            title={link.title}
            numberOfLinks={links.length}
            description={link.description}
            url={link.url}
            key={link.title}
            targetBlank={isExternalLinkProduct(link)}
            blockTracking={blockTracking}
          />
        )
      })}
    </div>
  )
}

export default LinkBlock
